<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
;
;
;
;
;
import { GetPositionTacheService, InitPositionTacheService } from '../../services/position-tache.service';
import { GetSuperpositionTachesService } from '../../services/superposition-taches.service';
import { GetServeurHTTPService } from '../../services/serveur-http.service';
import { InitTachesAbsencesService } from '../../services/taches-absences.service';
import { GetDeplacerTacheService } from '../../services/deplacer-tache.service';
import { GetSelectionTacheService } from '../../services/selection-tache.service';
import { GetAppelMessageService } from '../../services/appel-message.service';
import { GetAppelConfirmationService } from '../../services/appel-confirmation.service';
import { InitIntegrationWebappService } from '../../services/integration-webapp.service';
import { GetConfirmationSMSService } from '../../services/confirmation-sms.service';
import { GetMasquerTooltipsService } from '../../services/masquer-tooltips.service';
import GrillePlanning from './GrillePlanning.svelte';
import CreationTaches from './CreationTaches.svelte';
import FiltrePlanning from './FiltrePlanning.svelte';
import Message from '../utilitaires/Message.svelte';
import Confirmation from '../utilitaires/Confirmation.svelte';
import { writable } from 'svelte/store';
import moment from 'moment';
let heureOuverture = '09:00';
let heureFermeture = '17:00';
let granularite = 15; // minutes
let heures = 8;
let parametresLus = false;
let planningCalendrierLundi = false;
let planningCalendrierMardi = false;
let planningCalendrierMercredi = false;
let planningCalendrierJeudi = false;
let planningCalendrierVendredi = false;
let planningCalendrierSamedi = false;
let planningCalendrierDimanche = false;
let parametres;
const filtrePlanning = writable({
    nbJours: (window.innerWidth < 600) ? 1 : 3,
    collaborateursAffiches: [],
    cabinesAffichees: [],
    dateJour1: moment().format('YYYY-MM-DD'),
    joursFermes: false
});
const deplacerTacheService = GetDeplacerTacheService();
let http = GetServeurHTTPService();
const lireParametres = () => {
    http.get('/planning/api/lire-parametres-planning').then(param => {
        if (param.planningGranularite) {
            granularite = param.planningGranularite;
            deplacerTacheService.changerGranularite(granularite);
        }
        let typeSalon = param.typeSalon;
        if (param.planningHeureDebut) {
            heureOuverture = param.planningHeureDebut.substring(0, 5);
        }
        if (param.planningHeureFin) {
            heureFermeture = param.planningHeureFin.substring(0, 5);
        }
        planningCalendrierLundi = param.planningCalendrierLundi;
        planningCalendrierMardi = param.planningCalendrierMardi;
        planningCalendrierMercredi = param.planningCalendrierMercredi;
        planningCalendrierJeudi = param.planningCalendrierJeudi;
        planningCalendrierVendredi = param.planningCalendrierVendredi;
        planningCalendrierSamedi = param.planningCalendrierSamedi;
        planningCalendrierDimanche = param.planningCalendrierDimanche;
        parametres = param;
        InitIntegrationWebappService(param.typeSalon);
        heures = moment(heureFermeture, 'HH:mm').diff(moment(heureOuverture, 'HH:mm'), 'hours', true);
        parametresLus = true;
    });
};
filtrePlanning.subscribe(parametres => {
    if (parametres.joursFermes) {
        InitPositionTacheService(parametres.collaborateursAffiches, parametres.cabinesAffichees, heureOuverture, heureFermeture, granularite, parametres.nbJours, parametres.dateJour1, true, true, true, true, true, true, true);
    }
    else {
        InitPositionTacheService(parametres.collaborateursAffiches, parametres.cabinesAffichees, heureOuverture, heureFermeture, granularite, parametres.nbJours, parametres.dateJour1, planningCalendrierLundi, planningCalendrierMardi, planningCalendrierMercredi, planningCalendrierJeudi, planningCalendrierVendredi, planningCalendrierSamedi, planningCalendrierDimanche);
    }
});
let taches = writable([]);
let collaborateurs = writable([]);
let cabines = writable([]);
const superpositionTachesService = GetSuperpositionTachesService();
taches.update(taches => {
    return superpositionTachesService.superposerTaches(taches);
});
const masquerTooltipsService = GetMasquerTooltipsService();
const lectureTaches = (dateJour1, nbJours) => {
    taches.set([]);
    masquerTooltipsService.effacerTaches();
    let nbJoursCalcule = nbJours;
    if (nbJours !== 1 && nbJours !== 7) { // demande le nombre de jours nécessaires pour en afficher 3
        let n = 0;
        const positionTacheService = GetPositionTacheService();
        const momentJour1 = moment(dateJour1);
        const momentJour = moment(dateJour1, 'YYYY-MM-DD');
        for (let i = 0; i < Math.min(nbJours, positionTacheService.nbJoursMax()); i++) {
            while (!positionTacheService.jourEstAffiche(momentJour.isoWeekday()) && momentJour.diff(momentJour1, 'days') < 7) {
                momentJour.add(1, 'days');
                n++;
            }
            momentJour.add(1, 'days');
            n++;
        }
        nbJoursCalcule = n;
    }
    http.get('/planning/api/lire-taches?dateJour1=' + dateJour1 + '&nbJours=' + nbJoursCalcule).then(reponse => {
        for (const tache of reponse) {
            tache.gauche = false;
            tache.droite = false;
            tache.clickAndCollect = false;
        }
        collaborateurs.subscribe(col => {
            const nouvellesTaches = reponse;
            let service = InitTachesAbsencesService(col, parametres);
            const absences = service.getTachesAbsences(dateJour1, nbJoursCalcule);
            let clickAndCollect;
            for (const collaborateur of col) {
                if (collaborateur.statut === 8) {
                    clickAndCollect = collaborateur.IDCollaborateur;
                    break;
                }
            }
            if (clickAndCollect) {
                for (const tache of nouvellesTaches) {
                    if (tache.IDCollaborateur === clickAndCollect) {
                        tache.clickAndCollect = true;
                    }
                }
            }
            taches.set(superpositionTachesService.superposerTaches(nouvellesTaches.concat(absences)));
        })();
    });
};
const selectionTacheService = GetSelectionTacheService();
let tacheModification = null;
let clientTacheModification = null;
const rdvCree = (cree, dateFin, client) => {
    selectionTacheService.deselectionnerTache();
    afficherCreation = false;
    tacheModification = null;
    clientTacheModification = null;
    if (cree) {
        filtrePlanning.subscribe(parametres => {
            lectureTaches(parametres.dateJour1, parametres.nbJours);
            if (dateFin) {
                clientTacheModification = client;
                heureSelectionnee = dateFin.substring(11);
                afficherCreation = true;
            }
        })();
    }
};
filtrePlanning.subscribe(parametres => {
    lectureTaches(parametres.dateJour1, parametres.nbJours);
});
let aucunCollaborateur = false;
const lireCabinesEtCollaborateurs = () => {
    http.get('/planning/api/lire-cabines').then(listeCabines => {
        cabines.set(listeCabines);
        http.get('/planning/api/lire-collaborateurs').then(reponse => {
            if (!reponse || reponse.length === 0) {
                aucunCollaborateur = true;
            }
            const positionTacheService = InitPositionTacheService(reponse, [], heureOuverture, heureFermeture, granularite, $filtrePlanning.nbJours, $filtrePlanning.dateJour1, planningCalendrierLundi, planningCalendrierMardi, planningCalendrierMercredi, planningCalendrierJeudi, planningCalendrierVendredi, planningCalendrierSamedi, planningCalendrierDimanche);
            const cols = positionTacheService.getOrdreCollaborateurs(reponse);
            collaborateurs.set(cols);
            filtrePlanning.update(parametres => {
                if (parametres.collaborateursAffiches.length === 0) {
                    parametres.collaborateursAffiches = cols;
                }
                lectureTaches(parametres.dateJour1, parametres.nbJours);
                return parametres;
            });
        });
    });
};
const initDonneesPlanning = () => {
    lireParametres();
    lireCabinesEtCollaborateurs();
};
initDonneesPlanning();
window.initDonneesPlanning = initDonneesPlanning;
let jourSelectionne = '2020-09-11';
let collaborateurSelectionne = null;
let heureSelectionnee = '10:30:00';
let afficherCreation = false;
let cabineSelectionnee = null;
const selectionnerHeure = (event) => {
    jourSelectionne = event.detail.jour;
    heureSelectionnee = event.detail.heure + ':00';
    if (event && event.detail && event.detail.collaborateur) {
        collaborateurSelectionne = event.detail.collaborateur;
    }
    if (event && event.detail && event.detail.cabine) {
        cabineSelectionnee = event.detail.cabine;
    }
    masquerTooltipsService.masquerTooltips();
    afficherCreation = true;
};
const appelMessageService = GetAppelMessageService();
const confirmationSMSService = GetConfirmationSMSService();
const deplacerTache = () => {
    deplacerTacheService.getTacheFantome().subscribe((tache) => __awaiter(void 0, void 0, void 0, function* () {
        if (!tache) {
            return;
        }
        const tacheDeplacee = deplacerTacheService.tacheDeplacee;
        if (tache.dateDebut === tacheDeplacee.dateDebut && tache.dateFin === tacheDeplacee.dateFin
            && tache.IDCollaborateur === tacheDeplacee.IDCollaborateur && tache.IDCabine === tacheDeplacee.IDCabine) {
            return;
        }
        const reponse = yield http.post('/planning/api/deplacer-tache', {
            IDTache: tacheDeplacee.IDTache,
            IDCollaborateur: tache.IDCollaborateur,
            IDCabine: tache.IDCabine,
            dateDebut: tache.dateDebut,
            dateFin: tache.dateFin
        });
        if (reponse.statusCode) {
            appelMessageService.appelerMessage('Erreur lors de la modification du rendez-vous', () => { });
        }
        else if (tacheDeplacee.IDClient !== 0) {
            const clientSelectionne = yield http.get('/planning/api/lire-client?IDClient=' + tacheDeplacee.IDClient);
            yield confirmationSMSService.envoyerConfirmationSMS(clientSelectionne, tacheDeplacee, () => { rdvCree(true); });
        }
        else {
            rdvCree(true);
        }
    }))();
};
selectionTacheService.tacheSupprimee().subscribe(IDTache => {
    if (IDTache !== 0) {
        rdvCree(true);
    }
});
let message = '';
let valider = () => { };
let affichageMessage = false;
appelMessageService.getContenuReadable().subscribe(contenu => {
    message = contenu.message;
    valider = contenu.valider;
    affichageMessage = contenu.afficher;
});
let messageConfirmation = '';
let annulerConfirmation = () => { };
let validerConfirmation = () => { };
let affichageConfirmation = false;
const appelConfirmationService = GetAppelConfirmationService();
appelConfirmationService.getContenuReadable().subscribe(contenu => {
    messageConfirmation = contenu.message;
    annulerConfirmation = contenu.annuler;
    validerConfirmation = contenu.valider;
    affichageConfirmation = contenu.afficher;
});
selectionTacheService.getTacheModification().subscribe(tacheAModifier => {
    if (tacheAModifier) {
        tacheModification = tacheAModifier.tache;
        clientTacheModification = tacheAModifier.client;
        collaborateurs.subscribe(listeCollaborateurs => {
            for (const col of listeCollaborateurs) {
                if (col.IDCollaborateur === tacheModification.IDCollaborateur) {
                    collaborateurSelectionne = col;
                    break;
                }
            }
        })();
        jourSelectionne = tacheModification.dateDebut.substring(0, 10);
        heureSelectionnee = tacheModification.dateDebut.substring(11);
        masquerTooltipsService.masquerTooltips();
        afficherCreation = true;
    }
});
</script>

{#if aucunCollaborateur}
    <div class="aucun-collaborateur">Aucun collaborateur</div>
{/if}

{#if ($collaborateurs.length !== 0 && parametresLus)}
    <FiltrePlanning {filtrePlanning} {collaborateurs} {cabines} {taches}/>
    <GrillePlanning {heureOuverture} {heureFermeture} {granularite} {heures} {filtrePlanning} {taches} {deplacerTache} on:selectionnerHeure={selectionnerHeure}/>
    {#if afficherCreation}
        <CreationTaches {jourSelectionne} {collaborateurSelectionne} {cabineSelectionnee} {heureSelectionnee} {collaborateurs} {cabines} {rdvCree} {heureOuverture} {heureFermeture} {granularite} {tacheModification} {clientTacheModification} {taches}/>
    {/if}

    {#if affichageMessage}
        <Message valider={valider} message={message}/>
    {/if}

    {#if affichageConfirmation}
        <Confirmation annuler={annulerConfirmation} valider={validerConfirmation} message={messageConfirmation}/>
    {/if}
{/if}

<style>
    .aucun-collaborateur {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 20px;
        text-align: center;
    }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BsYW5uaW5nL0ZlbmV0cmVQbGFubmluZy5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtJQUNJO1FBQ0ksYUFBYTtRQUNiLHVCQUF1QjtRQUN2QixtQkFBbUI7UUFDbkIsWUFBWTtRQUNaLFdBQVc7UUFDWCxlQUFlO1FBQ2Ysa0JBQWtCO0lBQ3RCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3BsYW5uaW5nL0ZlbmV0cmVQbGFubmluZy5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcclxuICAgIC5hdWN1bi1jb2xsYWJvcmF0ZXVyIHtcclxuICAgICAgICBkaXNwbGF5OiBmbGV4O1xyXG4gICAgICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xyXG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XHJcbiAgICAgICAgaGVpZ2h0OiAxMDAlO1xyXG4gICAgICAgIHdpZHRoOiAxMDAlO1xyXG4gICAgICAgIGZvbnQtc2l6ZTogMjBweDtcclxuICAgICAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XHJcbiAgICB9XHJcbiJdfQ== */</style>