<svelte:head>
	<style>
		:root, body {
			height: 100%;
			width: 100%;
			overflow: hidden;
		}
	
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9BcHAuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7RUFDRTtHQUNDLFlBQVk7R0FDWixXQUFXO0dBQ1gsZ0JBQWdCO0VBQ2pCIiwiZmlsZSI6InNyYy9BcHAuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG5cdFx0OnJvb3QsIGJvZHkge1xuXHRcdFx0aGVpZ2h0OiAxMDAlO1xuXHRcdFx0d2lkdGg6IDEwMCU7XG5cdFx0XHRvdmVyZmxvdzogaGlkZGVuO1xuXHRcdH1cblx0Il19 */</style>
</svelte:head>

<script lang="ts">import { Router, Route, navigate } from 'svelte-routing';
import Login from './components/Login.svelte';
import FenetrePlanning from './components/planning/FenetrePlanning.svelte';
import { GetDeplacerTacheService } from './services/deplacer-tache.service';
import { GetMasquerTooltipsService } from './services/masquer-tooltips.service';
import { GetTokenLoginService } from './services/token-login.service';
let tokenLoginService = GetTokenLoginService();
tokenLoginService.getToken(valeur => {
    if (valeur === null && !location.hostname.includes('planning') && location.port !== '3000' && !location.pathname.includes('planning')) {
        // évite un changement d'url au chargement de la webapp
        return;
    }
    if (valeur !== null) {
        navigate('/planning/planning');
    }
    else {
        navigate('/planning');
    }
});
const deplacerTacheService = GetDeplacerTacheService();
const dragEnd = () => {
    // sécurité si on lache en-dehors d'une zone de déplacement valide pour une tâche
    setTimeout(() => {
        deplacerTacheService.deplacerTache(null);
    }, 200);
};
const masquerTooltipsService = GetMasquerTooltipsService();
const masquerTooltips = () => {
    /*
    masquerTooltipsService.masquerTooltips();
    */
};
</script>

<main on:mouseup={dragEnd} on:mousedown={masquerTooltips}>
	<Router>
		<!--
		<Link to="/">Login</Link>
		<Link to="planning">Planning</Link>
		-->
		<!--
		<Route path="/planning"><planning-login/></Route>
		<Route path="/planning/planning"><fenetre-planning/></Route>
		-->
		<Route path="/planning"><Login/></Route>
		<Route path="/planning/planning"><FenetrePlanning /></Route>
	</Router>
</main>

<style>
	main {
		display: flex !important;
		flex-direction: row;
		height: 100%;
		width: 100%;
		margin: 0 auto;
		background-color: white;
	}

	@media (orientation: portrait) {
		main {
			flex-direction: column;
		}
	}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9BcHAuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7Q0FDQztFQUNDLHdCQUF3QjtFQUN4QixtQkFBbUI7RUFDbkIsWUFBWTtFQUNaLFdBQVc7RUFDWCxjQUFjO0VBQ2QsdUJBQXVCO0NBQ3hCOztDQUVBO0VBQ0M7R0FDQyxzQkFBc0I7RUFDdkI7Q0FDRCIsImZpbGUiOiJzcmMvQXBwLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuXHRtYWluIHtcblx0XHRkaXNwbGF5OiBmbGV4ICFpbXBvcnRhbnQ7XG5cdFx0ZmxleC1kaXJlY3Rpb246IHJvdztcblx0XHRoZWlnaHQ6IDEwMCU7XG5cdFx0d2lkdGg6IDEwMCU7XG5cdFx0bWFyZ2luOiAwIGF1dG87XG5cdFx0YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7XG5cdH1cblxuXHRAbWVkaWEgKG9yaWVudGF0aW9uOiBwb3J0cmFpdCkge1xuXHRcdG1haW4ge1xuXHRcdFx0ZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcblx0XHR9XG5cdH1cbiJdfQ== */</style>