<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
;
;
import { GetServeurHTTPService } from '../../services/serveur-http.service';
import { writable } from 'svelte/store';
export let clientSelectionneWritable;
export let clientTrouve;
export let nouveauClient;
let http = GetServeurHTTPService();
let clients = [];
let chaineRecherche = writable('');
let clientSelectionne;
clientSelectionneWritable.subscribe(client => {
    if (client) {
        clientSelectionne = client;
        if (clientSelectionne.prenom) {
            chaineRecherche.set(client.nom + ' ' + client.prenom);
        }
        else {
            chaineRecherche.set(client.nom);
        }
    }
});
chaineRecherche.subscribe((chaine) => __awaiter(void 0, void 0, void 0, function* () {
    if (chaine === '') {
        clients = [];
    }
    else if (clientSelectionne && clientSelectionne.prenom && (clientSelectionne.nom + ' ' + clientSelectionne.prenom === chaine)) {
        return;
    }
    else if (clientSelectionne && (clientSelectionne.nom === chaine)) {
        return;
    }
    else {
        clientSelectionne = null;
        clients = yield http.get('/planning/api/rechercher-clients?chaine=' + chaine);
    }
}));
const selectionClient = (client) => {
    return () => {
        clientTrouve(client);
        clientSelectionne = client;
        clients = [];
        chaineRecherche.set(client.nom + ' ' + client.prenom);
    };
};
nouveauClient.subscribe(client => {
    if (client) {
        selectionClient(client)();
    }
});
</script>

<div class="cadre">
    <input type="text" bind:value={$chaineRecherche} autocomplete="off" placeholder="Rechercher un client">
    {#if (clients.length > 0)}
        <div class="liste-clients">
            {#each clients as client (client.IDClient)}
                <div class="client" on:click={selectionClient(client)}>{client.nom + ' ' + client.prenom}</div>
            {/each}
        </div>
    {/if}
</div>

<style>
    .cadre {
        position: relative;
        width: 100%;
        max-width: 250px;
        padding: 6px;
    }

    input {
        width: 100%;
        border: solid rgba(0, 0, 0, 0.7) 1px;
        border-radius: 6px;
        font-family: Roboto, sans-serif;
    }

    .liste-clients {
        position: absolute;
        top: 28px;
        left: 6px;
        width: 100%;
        border: solid rgba(0, 0, 0, 0.7) 1px;
        border-radius: 6px;
        background-color: white;
    }

    .client {
        cursor: pointer;
    }

    .client:hover {
        background-color: #337AB7;
        color: white;
    }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BsYW5uaW5nL1JlY2hlcmNoZUNsaWVudC5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtJQUNJO1FBQ0ksa0JBQWtCO1FBQ2xCLFdBQVc7UUFDWCxnQkFBZ0I7UUFDaEIsWUFBWTtJQUNoQjs7SUFFQTtRQUNJLFdBQVc7UUFDWCxvQ0FBb0M7UUFDcEMsa0JBQWtCO1FBQ2xCLCtCQUErQjtJQUNuQzs7SUFFQTtRQUNJLGtCQUFrQjtRQUNsQixTQUFTO1FBQ1QsU0FBUztRQUNULFdBQVc7UUFDWCxvQ0FBb0M7UUFDcEMsa0JBQWtCO1FBQ2xCLHVCQUF1QjtJQUMzQjs7SUFFQTtRQUNJLGVBQWU7SUFDbkI7O0lBRUE7UUFDSSx5QkFBeUI7UUFDekIsWUFBWTtJQUNoQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9wbGFubmluZy9SZWNoZXJjaGVDbGllbnQuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXHJcbiAgICAuY2FkcmUge1xyXG4gICAgICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcclxuICAgICAgICB3aWR0aDogMTAwJTtcclxuICAgICAgICBtYXgtd2lkdGg6IDI1MHB4O1xyXG4gICAgICAgIHBhZGRpbmc6IDZweDtcclxuICAgIH1cclxuXHJcbiAgICBpbnB1dCB7XHJcbiAgICAgICAgd2lkdGg6IDEwMCU7XHJcbiAgICAgICAgYm9yZGVyOiBzb2xpZCByZ2JhKDAsIDAsIDAsIDAuNykgMXB4O1xyXG4gICAgICAgIGJvcmRlci1yYWRpdXM6IDZweDtcclxuICAgICAgICBmb250LWZhbWlseTogUm9ib3RvLCBzYW5zLXNlcmlmO1xyXG4gICAgfVxyXG5cclxuICAgIC5saXN0ZS1jbGllbnRzIHtcclxuICAgICAgICBwb3NpdGlvbjogYWJzb2x1dGU7XHJcbiAgICAgICAgdG9wOiAyOHB4O1xyXG4gICAgICAgIGxlZnQ6IDZweDtcclxuICAgICAgICB3aWR0aDogMTAwJTtcclxuICAgICAgICBib3JkZXI6IHNvbGlkIHJnYmEoMCwgMCwgMCwgMC43KSAxcHg7XHJcbiAgICAgICAgYm9yZGVyLXJhZGl1czogNnB4O1xyXG4gICAgICAgIGJhY2tncm91bmQtY29sb3I6IHdoaXRlO1xyXG4gICAgfVxyXG5cclxuICAgIC5jbGllbnQge1xyXG4gICAgICAgIGN1cnNvcjogcG9pbnRlcjtcclxuICAgIH1cclxuXHJcbiAgICAuY2xpZW50OmhvdmVyIHtcclxuICAgICAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjMzM3QUI3O1xyXG4gICAgICAgIGNvbG9yOiB3aGl0ZTtcclxuICAgIH1cclxuIl19 */</style>