<script lang="ts">import { GetTokenLoginService } from '../services/token-login.service';
import { navigate } from 'svelte-routing';
let tokenLoginService = GetTokenLoginService();
const logout = () => {
    tokenLoginService.deleteToken();
    navigate('/planning/');
};
</script>

{#if window.location.hostname.includes('planning') || window.location.port === '3000'}
    <div class="logout" on:click={logout}>
    </div>
{:else}
    <div class="logout-vide">
    </div>
{/if}

<style>
    .logout {
        width: 27px;
        height: 27px;
        min-height: 27px;
        margin: 8px;
        background-color: black;
        -webkit-mask: url(/planning/assets/menu-power.svg) no-repeat center;
        mask: url(/planning/assets/menu-power.svg) no-repeat center;
        -webkit-mask-size: contain;
        mask-size: contain;
        cursor: pointer;
    }

    .logout-vide {
        width: 27px;
        height: 27px;
        min-height: 27px;
        margin: 8px;
    }

    @media (orientation: portrait) {
        .logout {
            position: absolute;
            left: 0;
            top: 0;
            margin-left: 12px;
            margin-top: 8px;
        }

        .logout-vide {
            position: absolute;
            left: 0;
            top: 0;
            margin-left: 12px;
            margin-top: 8px;
        }
    }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL0xvZ291dC5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtJQUNJO1FBQ0ksV0FBVztRQUNYLFlBQVk7UUFDWixnQkFBZ0I7UUFDaEIsV0FBVztRQUNYLHVCQUF1QjtRQUN2QixtRUFBbUU7UUFDbkUsMkRBQTJEO1FBQzNELDBCQUEwQjtRQUMxQixrQkFBa0I7UUFDbEIsZUFBZTtJQUNuQjs7SUFFQTtRQUNJLFdBQVc7UUFDWCxZQUFZO1FBQ1osZ0JBQWdCO1FBQ2hCLFdBQVc7SUFDZjs7SUFFQTtRQUNJO1lBQ0ksa0JBQWtCO1lBQ2xCLE9BQU87WUFDUCxNQUFNO1lBQ04saUJBQWlCO1lBQ2pCLGVBQWU7UUFDbkI7O1FBRUE7WUFDSSxrQkFBa0I7WUFDbEIsT0FBTztZQUNQLE1BQU07WUFDTixpQkFBaUI7WUFDakIsZUFBZTtRQUNuQjtJQUNKIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL0xvZ291dC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICAubG9nb3V0IHtcbiAgICAgICAgd2lkdGg6IDI3cHg7XG4gICAgICAgIGhlaWdodDogMjdweDtcbiAgICAgICAgbWluLWhlaWdodDogMjdweDtcbiAgICAgICAgbWFyZ2luOiA4cHg7XG4gICAgICAgIGJhY2tncm91bmQtY29sb3I6IGJsYWNrO1xuICAgICAgICAtd2Via2l0LW1hc2s6IHVybCgvcGxhbm5pbmcvYXNzZXRzL21lbnUtcG93ZXIuc3ZnKSBuby1yZXBlYXQgY2VudGVyO1xuICAgICAgICBtYXNrOiB1cmwoL3BsYW5uaW5nL2Fzc2V0cy9tZW51LXBvd2VyLnN2Zykgbm8tcmVwZWF0IGNlbnRlcjtcbiAgICAgICAgLXdlYmtpdC1tYXNrLXNpemU6IGNvbnRhaW47XG4gICAgICAgIG1hc2stc2l6ZTogY29udGFpbjtcbiAgICAgICAgY3Vyc29yOiBwb2ludGVyO1xuICAgIH1cblxuICAgIC5sb2dvdXQtdmlkZSB7XG4gICAgICAgIHdpZHRoOiAyN3B4O1xuICAgICAgICBoZWlnaHQ6IDI3cHg7XG4gICAgICAgIG1pbi1oZWlnaHQ6IDI3cHg7XG4gICAgICAgIG1hcmdpbjogOHB4O1xuICAgIH1cblxuICAgIEBtZWRpYSAob3JpZW50YXRpb246IHBvcnRyYWl0KSB7XG4gICAgICAgIC5sb2dvdXQge1xuICAgICAgICAgICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgICAgICAgICAgbGVmdDogMDtcbiAgICAgICAgICAgIHRvcDogMDtcbiAgICAgICAgICAgIG1hcmdpbi1sZWZ0OiAxMnB4O1xuICAgICAgICAgICAgbWFyZ2luLXRvcDogOHB4O1xuICAgICAgICB9XG5cbiAgICAgICAgLmxvZ291dC12aWRlIHtcbiAgICAgICAgICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICAgICAgICAgIGxlZnQ6IDA7XG4gICAgICAgICAgICB0b3A6IDA7XG4gICAgICAgICAgICBtYXJnaW4tbGVmdDogMTJweDtcbiAgICAgICAgICAgIG1hcmdpbi10b3A6IDhweDtcbiAgICAgICAgfVxuICAgIH1cbiJdfQ== */</style>